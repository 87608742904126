<template>
  <div class="card">
    <div class="flex">
      <div class="order">
        <div>订单号 {{ item.order_no }}</div>
        <div class="copyorder" @click="copyorder">复制单号</div>
      </div>
      <div class="ing">{{ item.status_text }}</div>
    </div>
    <div class="line"></div>
    <div class="card-detail">
      <img :src="item.cover" alt="" style="object-fit: cover;"/>
      <div class="desc" style="flex:1">
        <div class="name">{{ item.title }}</div>
        <div class="price">
          <div>在售最低价：¥{{ item.sale_min_price }}</div>
          <div>求购最高价：¥{{ item.entrust_max_price }}</div>
        </div>
      </div>
      <div style="font-size:14px;color:red;font-weight:800;margin-right:10px">¥{{item.price}}</div>
    </div>
    <div class="line"></div>
    <div class="bottom_flex">
      <div class="restDay" style="">
        求购剩余<span style="margin-left: 3px; margin-right: 3px">{{
          day
        }}</span
        >天
      </div>
      <div class="cancel" @click="cancel">取消</div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from "vue-property-decorator";
import DemandFlag from "@/views/demand/components/DemandFlag.vue";
import { copy, countdown } from "@/utils/common.util";
import { Toast } from "vant";
import SearchApi from "@/api/bazaar.api";
@Component({
  components: {
    DemandFlag,
    Toast,
  },
})
export default class DetailCard extends Vue {
  @Prop() item;
  order = "";
  day
  created() {
    this.item.expire_time = countdown(this.item.expire_time);
    if(this.item.expire_time.day.slice(0,1) == 0){
      this.day = this.item.expire_time.day.slice(1)
    }else{
      this.day = this.item.expire_time.day
    }
    this.order = this.item.order_no;
    this.item.order_no =
      this.item.order_no.slice(0, 3) + "***" + this.item.order_no.slice(-4);
  }
  copyorder() {
    copy(this.order);
    Toast.success('复制成功')
  }
  async cancel() {
    await SearchApi.cancelDemand(this.item.id)
      .then(() => {
        Toast.success("取消成功");
        this.$emit("delete", this.item.id);
      })
      .catch((err) => Toast.fail({ message: err.message }));
  }
}
</script>

<style lang="scss" scoped>
.card {
  background: white;
  border-radius: 8px;
  margin: 10px auto;
  .bottom_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    .restDay {
      font-size: 12px;
      color: #969696;
    }
    .cancel {
      width: 59px;
      height: 28px;
      background: #ffffff;
      border-radius: 14px;
      border: 1px solid #dddddd;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .line {
    height: 1px;
    width: 100%;
    background: #f2f2f2;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    .order {
      font-size: 12px;
      color: #969696;
      display: flex;
      align-items: center;
      .copyorder {
        width: 58px;
        height: 18px;
        background: #ecedee;
        border-radius: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
        margin-left: 5px;
        font-size: 10px;
      }
    }
    .ing {
      color: black;
      font-size: 12px;
      font-weight: 800;
    }
  }
  .card-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    img {
      width: 63px;
      height: 63px;
      border-radius: 4px;
    }
    .desc {
      flex: 1;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin-left: 10px;
      .name {
        overflow: hidden; /*超出部分隐藏*/
        text-overflow: ellipsis; /* 超出部分显示省略号 */
        white-space: nowrap; /*规定段落中的文本不进行换行 */
        width: 180px; /*需要配合宽度来使用*/
        color: black;
        font-size: 16px;
      }
      .price {
        margin-top: 10px;
        font-size: 13px;
        color: #969696;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
</style>
