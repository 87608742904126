<template>
  <div>
    <div class="wrap intro">
      <div style="">
        <Tabs v-model="active" swipeable sticky line-width="16px" color="black">
          <Tab title="正在求购" style="z-index: 99">
            <div v-if="mineList.length > 0" style="padding: 0 10px">
              <List
                v-model="loading0"
                :finished="finished0"
                finished-text="没有更多了"
                @load="onLoad0m"
                :immediate-check="false"
              >
                <DetailCard
                  @delete="deleteDemand"
                  v-for="(item, index) in mineList"
                  :key="index"
                  :item="item"
                />
              </List>
            </div>
            <EmptyBox
              tip="暂无求购商品"
              :haveChange="true"
              v-else
              style="height: 500px; top: 100px"
            />
          </Tab>
          <Tab title="求购记录">
            <div v-if="DemandList.length > 0" style="padding: 0 10px">
              <List
                v-model="loading1"
                :finished="finished1"
                finished-text="没有更多了"
                @load="onLoad1m"
                :immediate-check="false"
              >
                <DetailRecord
                  v-for="(item, index) in DemandList"
                  :key="index"
                  :item="item"
                />
              </List>
            </div>
            <EmptyBox
              tip="暂无求购记录"
              :haveChange="true"
              v-else
              style="height: 500px; top: 100px"
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import { Tab, Tabs, List } from "vant";
import DetailCard from "@/views/demand/components/DetailCard.vue";
import DetailRecord from "@/views/demand/components/DetailRecord.vue";
import SearchApi from "@/api/bazaar.api";
import EmptyBox from "@/components/EmptyBox.vue";
import { observer } from "@/utils/common.util";

@Component({
  components: {
    Tab,
    Tabs,
    DetailCard,
    DetailRecord,
    EmptyBox,
    List,
  },
})
export default class Product extends Vue {
  active = 0;
  mineList = [];
  DemandList = [];
  loading0 = false;
  finished0 = false;
  page0 = 1;
  page_size = 10;

  async onLoad0m() {
    this.page0++;
    let res = await SearchApi.getMydemand(this.page0, this.page_size);
    res.forEach((item) => {
      this.mineList.push(item);
    });
    this.loading0 = false;
    if (res.length < 10) {
      this.finished0 = true;
    }
  }

  loading1 = false;
  finished1 = false;
  page1 = 1;

  async onLoad1m() {
    this.page1++;
    let res = await SearchApi.getMydemand(this.page1, this.page_size, 1);
    res.forEach((item) => {
      this.DemandList.push(item);
    });
    this.loading1 = false;
    if (res.length < 10) {
      this.finished1 = true;
    }
  }

  mounted() {
    observer("refreshInit", () => {
      this.init();
    });
  }

  async created() {
   this.init()
  }
  async init(){
     if (this.$route.query.active) {
      this.active = Number(this.$route.query.active);
    }
    this.mineList = await SearchApi.getMydemand(this.page0, this.page_size);
    this.DemandList = await SearchApi.getMydemand(
      this.page1,
      this.page_size,
      1
    );
  }
  deleteDemand(id) {
    this.mineList = this.mineList.filter((item) => {
      if (item.id != id) {
        return item;
      }
    });
  }
}
</script>

<style lang="scss" scoped>
.intro {
  ::v-deep {
    .van-sticky--fixed {
      background: white;
    }

    .van-tabs__wrap {
      .van-tabs__nav {
        width: 200px;

        .van-tab--active {
          font-weight: 800;
        }

        .van-tabs__line {
          bottom: 19px !important;
          height: 2px;
        }
      }
    }
  }
}

.wrap {
  ::v-deep {
    .van-sticky {
      background: white;

      .van-tabs__wrap {
        width: 200px;

        .van-tabs__nav {
          .van-tabs__line {
            bottom: 19px !important;
          }
        }
      }
    }
  }
}
</style>
