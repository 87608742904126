<template>
  <div class="Demand">
    <div v-if="status == 1">
      <div class="red" style="font-weight: 600">¥{{ price }}</div>
    </div>
    <template v-else>
      <div v-if="status == 3" class="green">求购成功</div>
      <div class="red" v-else>求购失败</div>
    </template>
  </div>
</template>

<script>
import { Component, Vue, Prop } from "vue-property-decorator";
@Component({
  components: {},
})
export default class DemandFlag extends Vue {
  @Prop() status;
  @Prop() price;
}
</script>

<style lang="scss" scoped>
.Demand {
  font-size: 12px;
  .red {
    color: red;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .green {
    color: green;
  }
}
</style>
